<template>
  <b-card no-body>
    <b-card-body>
      <Filters />
      <Results />
    </b-card-body>                
  </b-card>
</template>

<script>
//components
import Filters from '@/modules/reports/components/Filters'
import Results from '@/modules/reports/components/Results'

export default {
  components: {
    Filters,
    Results,
  },
  data() {
    return {
      
    }
  },
  computed: {
   
  },
  created() {
    
  },
  methods: {
   
  },
}
</script>
